import {createAppAsyncThunk} from "@src/store/withTypes";
import HttpError, {extractStatusCode} from "@src/shared/http-error";
import {ParkDomainModel} from "@src/dashboards/parks/core/models/park.domain-model";
import {fetchParkFailed} from "@src/dashboards/parks/core/store/park";

export const fetchPark = createAppAsyncThunk(
  "dashboardPark/fetchPark",
  async (params: URLSearchParams, {dispatch, extra: { httpGateway }}
  ): Promise<ParkDomainModel.Park> => {
    const parkId = params.get("parc");
    const url = `/api/v1/dashboard/park/${parkId}?${params}`;
    try {
      const response = await httpGateway.get<ParkDomainModel.Park>(url);
      return response.data;
    } catch (e) {
      const statusCode = extractStatusCode(e);
      dispatch(fetchParkFailed(statusCode));
      throw new HttpError(statusCode, e.message, {});
    }
  });