import { GridColDef } from "@mui/x-data-grid";
import { RootState } from "@src/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

export function useBuildingSynthesisPresenter() {
  const {t, i18n} = useTranslation("dashboardPark");
  const [pageSize, setPageSize] = useState(5);
    
  // Memoized selectors 
  const buildingsSelector = (state: RootState) => state.parkDashboard.selectedBuildings;
  const dataSelector = (state: RootState) => state.parkDashboard.consumption.data;
  const selectData = createSelector([buildingsSelector, dataSelector], (selectedBuildings, data) => {
    const buildingsIds = selectedBuildings?.map(b => b.id);
    return data?.filter(d => buildingsIds.includes(d.building.id)) ?? [];
  });
  const data = useSelector(selectData);

  
  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, [i18n]);

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  const formatNumber = (value: number) => {
    if (value === undefined || value === null) {
      return "-";
    }
    return new Intl.NumberFormat(i18n.language).format(value);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("Building").toLocaleUpperCase(),
      flex: 1,
    },
    {
      field: "constructionYear",
      headerName: t("Construction").toLocaleUpperCase(),
      type: "number",
      flex: 1,
    },
    {
      field: "surface",
      headerName: `${t("Surface")} (m²)`.toLocaleUpperCase(),
      type: "number",
      flex: 1,
    },
    {
      field: "elec_consumed",
      headerName: t("Electricity").toLocaleUpperCase(),
      type: "number",
      flex: 1,
    },
    {
      field: "gas_consumed",
      headerName: `${t("Gaz")} (MWh)`.toLocaleUpperCase(),
      type: "number",
      flex: 1,
    },
    {
      field: "hot_consumed",
      headerName: `${t("Heating")} (MWh)`.toLocaleUpperCase(),
      type: "number",
      flex: 1,
    },
    {
      field: "cold_consumed",
      headerName: `${t("Cooling")} (MWh)`.toLocaleUpperCase(),
      type: "number",
      flex: 1,
    },
    {
      field: "expenses",
      headerName: `${t("Total cost")} €`.toLocaleUpperCase(),
      type: "number",
      flex: 1,
    },
  ];

  const rows = data.map(d => ({
    id: d.building.id,
    name: d.building.name,
    constructionYear: d.building.informations.constructionYear ?? "-",
    surface: d.building.informations.surface ?? "-",
    elec_consumed: formatNumber(d.energies.electricity?.consumed),
    gas_consumed: formatNumber(d.energies.gas?.consumed),
    hot_consumed: formatNumber(d.energies.calorie?.consumed),
    cold_consumed: formatNumber(d.energies.cold_calorie?.consumed),
    expenses: formatNumber(d.expenses_vat_excl),
  }));

  const localeText = {
    MuiTablePagination: {
      labelRowsPerPage: t("Rows per page"),
      labelDisplayedRows: ({ from, to, count }: { from: number, to: number, count: number }) =>
        `${from}–${to} ${t("of")} ${count}`,
    },
  };

  return {
    t,
    columns,
    rows,
    pageSize,
    handlePageSizeChange,
    rowsPerPageOptions: [5, 10, 50],
    localeText
  };
}
