import {useTranslation} from "react-i18next";
import {ParkViewModel} from "@src/dashboards/parks/core/models/park.view-model";
import { SelectChangeEvent } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";


export function useYearPresenter(setYear: (year: string) => void) {
  const {t, i18n} = useTranslation("common");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const years = [];
  for (let year = new Date().getFullYear(); year >= 2010; year--) {
    years.push(year);
  }

  const yearOptions: ParkViewModel.SelectOption[] = [{
    "label": t("12 rolling months"), "value": "default", "type": "year"
  }].concat(years.map(y => ({"label": `${y}`, "value": `${y}`, "type": "year"})));


  const handleYearChange = (event: SelectChangeEvent) => {
    const newSelectedYear = event.target.value;
    setYear(newSelectedYear);

    const params = new URLSearchParams(searchParams);
    params.set("year", newSelectedYear);
    navigate(`?${params.toString()}`, { replace: true });
  };

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);

    // On unmount, remove the year from the url
    return () => {
      const params = new URLSearchParams(searchParams);
      params.delete("year");
      navigate(`?${params.toString()}`, { replace: true });
    };
  }, []);
  
  return {
    yearOptions,
    handleYearChange
  };
}