import {
  PeriodAverage
} from "@src/dashboards/comfort/components/data/sensor-analysis";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {useTranslation} from "react-i18next";


interface Props {
    data: PeriodAverage
}
export default function Co2PeriodAverage({data}: Props) {
  const {t} = useTranslation("dashboard");

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">{t("Average")}</TableCell>
            <TableCell align="right">{t("Maximum")}</TableCell>
            <TableCell align="right">{t("Minimum")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {t("Occupied")}
            </TableCell>
            <TableCell align="right">{data?.occupied ?? "N/A"}&nbsp;ppm</TableCell>
            <TableCell align="right">{data?.max_occupied ?? "N/A"}&nbsp;ppm</TableCell>
            <TableCell align="right">{data?.min_occupied ?? "N/A"}&nbsp;ppm</TableCell>
          </TableRow>
          <TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {t("Inoccupation")}
            </TableCell>
            <TableCell align="right">{data?.unoccupied ?? "N/A"}&nbsp;ppm</TableCell>
            <TableCell align="right">{data?.max_unoccupied ?? "N/A"}&nbsp;ppm</TableCell>
            <TableCell align="right">{data?.min_unoccupied ?? "N/A"}&nbsp;ppm</TableCell>
          </TableRow>
          <TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {t("Weekend")}
            </TableCell>
            <TableCell align="right">{data?.weekend ?? "N/A"}&nbsp;ppm</TableCell>
            <TableCell align="right">{data?.max_weekend ?? "N/A"}&nbsp;ppm</TableCell>
            <TableCell align="right">{data?.min_weekend ?? "N/A"}&nbsp;ppm</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}