import { DataGrid } from "@mui/x-data-grid";

import { useBuildingSynthesisPresenter } from "./use-building-synthesis-presenter";
import { Box } from "@mui/material";

export function BuildingSynthesis() {
  const presenter = useBuildingSynthesisPresenter();
  const {t} = presenter;

  return (
    <>
      <div className="card text-center">
        <div className="card-body">
          <h4 className="card-title pb-2">
            {t("Building synthesis")}
          </h4>
          <Box sx={{ width: "100%" }}>
            <DataGrid   
              rows={presenter.rows}
              columns={presenter.columns}
              pageSize={presenter.pageSize}
              rowsPerPageOptions={presenter.rowsPerPageOptions}
              disableSelectionOnClick
              pagination
              autoHeight
              onPageSizeChange={presenter.handlePageSizeChange}
              localeText={presenter.localeText}
            />
          </Box>
        </div>
      </div>
    </>
  );
}