import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import {useYearPresenter} from "@src/dashboards/parks/react/sections/year-selector/use-year-selector";

type Props = {
  selectedYear: string;
  setYear: (year: string) => void;
}

export function YearSelector({selectedYear, setYear}: Props) {
  const presenter = useYearPresenter(setYear);

  return (
    <>
      <div className="col"></div>
      <div className="col"></div>
      <div className="col"></div>
      <div className="col row justify-content-end mr-2">
        <FormControl sx={{ m: 1, minWidth: 180, backgroundColor: "white"}}>
          <Select
            data-testid="year-selector"
            value={selectedYear}
            size="small"
            onChange={presenter.handleYearChange}
          >
            {
              presenter.yearOptions.map(option => (
                <MenuItem key={option.value+option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </div>
    </>
  );
}