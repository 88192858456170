// import { useSelector } from "react-redux";
import Slider, { Settings } from "react-slick";
import { ResponsiveContainer } from "recharts";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
// import { RootState } from "../../../context/store";
import { useState } from "react";
import BarChart from "./bar-chart";
import PieChart from "./pie-chart";

interface PropsDetails {
  data?: any;
  name?: string;
  titleValue: number;
  unit?: string;
  children?: any;
  type?: string;
  showText?: boolean,
  setShowText?: (value:boolean) => void;
}

interface Props {
  chartId: string;
  header: any;
}

export function HeaderChart({ chartId, header: response }: Props) {
  const settings: Settings = {
    dots: true,
  };
  const [showText, setShowText] = useState(false);

  const style = {
    volume: {width: "50%", margin: "auto"},
    energy: {width: "50%", margin: "auto"}
  };

  const charts = {
    "line-elec": () => (
      <div style={{ width: "100%", overflow: "visible" }}>
        <Slider {...settings}>
          {response.elec?.map((data: any) => {
            return (
              <div key={data.sensorName + "header-elec"}>
                <HeaderChartDetail
                  name={data.sensorName}
                  titleValue={data.total_consumed || 0}
                  setShowText={setShowText}
                  showText={showText}
                >
                  <div className="text-center w-25">
                    {/* <h3 style={{width: 375}}>{gettext("Répartition de la consommation en fonction de l'occupation")}</h3>    */}
                    {
                      showText && <h3>
                        {gettext(
                          "Répartition de la consommation par tranche horosaisonnière"
                        )}
                      </h3>
                    }
                    <PieChart data={data.consumption} setShowText={setShowText}/>
                  </div>
                  <div className="w-25">
                    <BarChart data={data.power} color="#A3C300" setShowText={setShowText} />
                  </div>
                  <div className="text-center w-25">
                    {/* <h3 style={{width: 375}}>{gettext("Répartition du volume en fonction de l'occupation")}</h3> */}
                    {
                      showText && <h3>
                        {gettext(
                          "Répartition de la consommation en fonction de l'occupation"
                        )}
                      </h3>
                    }
                    <PieChart data={data.activity} />
                  </div>
                </HeaderChartDetail>
              </div>
            );
          })}
        </Slider>
      </div>
    ),
    "line-hot": () => (
      <div style={{ width: "95%" }}>
        <Slider {...settings}>
          {response.hot?.map((data: any, idx: number) => {
            return (
              <div key={data.sensorName + "header-hot" + idx}>
                <HeaderChartDetail
                  name={data.sensorName}
                  data={data.activity}
                  titleValue={data.total_consumed}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    ),
    "line-cold": () => (
      <div style={{ width: "95%", display: "flex" }}>
        {
          response.cold.energy.map((payload: any) => {
            return (
              <div key={payload.sensorName + "header-cold"}>
                <div className="text-center" style={style.energy}>
                  <h3>{gettext("Répartition de la consommation en fonction de l'occupation")}</h3>
                  <HeaderChartDetail
                    name={payload.sensorName}
                    data={payload.activity}
                    titleValue={payload.total_consumed}
                  />
                </div>
              </div>
            );
          })
        }
        {
          response.cold.volume.map((payload: any) => {
            return (
              <div key={payload.sensorName + "header-cold"}>
                <div className="text-center" style={style.volume}>
                  <h3>{gettext("Répartition du volume en fonction de l'occupation")}</h3>
                  <HeaderChartDetail
                    name={payload.sensorName}
                    data={payload.activity}
                    titleValue={payload.total_consumed}
                  />
                </div>
              </div>
            );
          })
        }
      </div>
    ),
  };
  const chart = charts[chartId as keyof typeof charts];
  const render = response && chart && chart();

  return <>{render}</>;
}

export function HeaderChartDetail({
  data,
  name,
  titleValue,
  children,
  showText,
  setShowText,
  type = "piechart",
}: PropsDetails) {
  const lang = document.documentElement.lang;
  const getIntNumber = (value: number) => {
    return new Intl.NumberFormat(`${lang}-${lang.toUpperCase()}`).format(value);
  };

  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <div className="d-flex flex-column" style={{overflow: "visible"}}>
        {
          showText && <div
            className="titles text-center position-relative mb-4"
            style={children ? {} : { top: "35px" }}
          >
            <h1 style={{minWidth: "300px", margin: "auto"}}>
              {getIntNumber(titleValue)}{" "}
              {(data?.length && data[0].unit) ?? "kWh EF"}
            </h1>
          
            <h2 style={{ fontSize: "1.4rem", color: "rgba(0, 0, 0, 0.54)"}}>
              {name}
            </h2>
          </div>
        }

        <div className="d-flex justify-content-around align-items-center" style={{overflow: "visible"}}>
          {children ? (
            <>{children}</>
          ) : type === "piechart" ? (
            <PieChart data={data} setShowText={setShowText} />
          ) : (
            <BarChart data={data} color="#A3C300" setShowText={setShowText}/>
          )}
        </div>
      </div>
    </ResponsiveContainer>
  );
}
