import { ParkDomainModel } from "../models/park.domain-model";
import { ParkViewModel } from "../models/park.view-model";
import { removeDuplication } from "@src/common/utils";

export class Filter {
  constructor(
    public cities: string[] = [],
    public networks: string[] = [],
    public constructionYears: string[] = [],
    public mainActivities: string[] = [],
  ) {}

  /** We use a function instead of a method to avoid the "this" context to be undefined in array.filter method */
  isMatchingCriteria = (building: ParkDomainModel.Building): boolean => {
    const matchesRegions = this.cities.length === 0 || this.cities.includes(building.informations.city);
    const matchesNetworks = this.networks.length === 0 || !!this.networks.find(
      network => building.configuration[network as keyof ParkDomainModel.BuildingConfiguration]
    );
    const matchesMainActivities = this.mainActivities.length === 0 || this.mainActivities.includes(building.informations.mainActivity);

    const constructionWhithinInterval = this.constructionYears.some(option => {
      const constructionYear = +building.informations?.constructionYear;
      const years = option.split("-").map((d: string) => +d);
      return constructionYear >= years[0] && constructionYear <= years[1];
    });
    const matchesConstructionYear =  this.constructionYears.length === 0 || constructionWhithinInterval;

    return matchesRegions && matchesMainActivities && matchesConstructionYear && matchesNetworks;
  };

  generateSelectOption(buildings: ParkDomainModel.Building[]) {
    const tmpFilters: ParkViewModel.FilterOption = {
      cities: [],
      networks: [],
      constructionYears: [],
      mainActivities: []
    };

    const networkOptions = {
      urban_network: "Heating network",
      cooling_network: "Cooling network",  
      electricity_network: "Electricity",
      gas_network: "Gas",
      water_network: "Water",
    };

    for (const building of buildings) {
      const {city, constructionYear, mainActivity} = building.informations;
      const configuration = building.configuration;
      if (city) {
        tmpFilters.cities.push({label: city, value: city, type: "cities"});
      }
      
      if (configuration) {
        for (const key of Object.keys(configuration)) {
          const hasNetwork = configuration[key as keyof ParkDomainModel.BuildingConfiguration] === true;
          if (key.includes("network") && hasNetwork ) {
            tmpFilters.networks.push(
              {label: networkOptions[key as keyof typeof networkOptions], value: key, type: "networks"}
            );
          }
        }
      }
      if (mainActivity) {
        tmpFilters.mainActivities.push({label: mainActivity, value: mainActivity, color: "", type: "mainActivities"});
      }
      if (constructionYear) {
        const fixedConstructionYearOptions: readonly ParkViewModel.SelectOption[] =  [
          {"label": "Before 1974", "value": "0-1974", color: "", type: "constructionYears"},
          {"label": "Between 1974 and 1988", "value": "1974-1988", color: "", type: "constructionYears"},
          {"label": "Between 1988 and 2000", "value": "1988-2000", color: "", type: "constructionYears"},
          {"label": "Between 2000 and 2005", "value": "2000-2005", color: "", type: "constructionYears"},
          {"label": "Between 2005 and 2012", "value": "2005-2012", color: "", type: "constructionYears"},
          {"label": "After 2012", "value": "2012-9999", color: "", type: "constructionYears"}
        ];
        tmpFilters.constructionYears.push(...fixedConstructionYearOptions);
      }
    }
    
    const filterOption: ParkViewModel.FilterOption = {
      cities: removeDuplication(tmpFilters.cities),
      networks: removeDuplication(tmpFilters.networks),
      constructionYears: removeDuplication(tmpFilters.constructionYears),
      mainActivities: removeDuplication(tmpFilters.mainActivities),
    };
    
    return filterOption;
  }

  update(values: string[], type: string) {
    const key = type as "cities" | "networks" | "constructionYears" | "mainActivities";
    this[key] = [...new Set(values)];
  }
}
