import {Dependencies} from "@src/app/dependencies";
import {AppStore, createStore} from "@src/store";
import {AxiosGateway} from "@src/core/adapters/system.http-gateway";
import { SystemDateProvider } from "@src/core/adapters/system.date-provider";

export class App {
  public dependencies: Dependencies;
  public store: AppStore;

  constructor() {
    this.dependencies = this.setupDependencies();
    this.store = createStore({dependencies: this.dependencies});
  }

  setupDependencies(): Dependencies {
    return {
      httpGateway: new AxiosGateway(),
      dateProvider: new SystemDateProvider()
    };
  }
}

export const app = new App();