import styled from "styled-components";
import {units, unitsPrecision} from "@src/dashboards/comfort/components/data/unit-types";
import type { Units } from "@src/dashboards/comfort/components/data/unit-types";
import {hasMetricReachLimit} from "@src/common/utils";
import { AirQualityThreshold, TemperatureThreshold } from "./threshold-types";
import { OccupancyTimeCharacteristics } from "@src/common/api/building";

const StyledSpan = styled.span<{hasReachLimit?: boolean}>`
  font-weight: ${({hasReachLimit}) => hasReachLimit ? "bold" : "normal"};
  color: ${({hasReachLimit}) => hasReachLimit ? "#EE335E": "#000000"};
`;

type MetricProps = {
    unit: Units;
    value: number | null;
    threshold: TemperatureThreshold | AirQualityThreshold;
    last_message_ts: number;
    occupancyTime?: OccupancyTimeCharacteristics;
}

export default function Metric ({unit, value, last_message_ts, threshold, occupancyTime}: MetricProps) {
  const hasMetricReachedLimit = hasMetricReachLimit(unit, value, last_message_ts, threshold, occupancyTime);
  const metricValue = value?.toFixed(unitsPrecision[unit]) ?? "-";

  return (
    <div>
      <StyledSpan hasReachLimit={hasMetricReachedLimit}>
        {metricValue}
      </StyledSpan>
            &nbsp;
      {units[unit]}
    </div>
  );
}

