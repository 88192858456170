import { Filter } from "@src/dashboards/parks/core/filters/buildings-filter";
import { ParkViewModel } from "@src/dashboards/parks/core/models/park.view-model";
import { selectedBuildingsUpdated } from "@src/dashboards/parks/core/store/park";
import { RootState, useAppDispatch } from "@src/store";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export function useBuildingFitlerPresenter() {
  const {t, i18n} = useTranslation(["dashboardPark", "common"]);
  const dispatch = useAppDispatch();
  const buildings = useSelector((state: RootState) => state.parkDashboard.detail.data?.buildings) ?? [];
  
  const filter = useRef(new Filter());
  const options = filter.current.generateSelectOption(buildings);
  
  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);                 

  const onChangeHandler = (options: ParkViewModel.SelectOption[], type: ParkViewModel.FilterOptionKeys) => {
    const values = options.map(option => option.value);
    filter.current.update(values, type);

    const selectedBuildings = buildings.filter(filter.current.isMatchingCriteria);
    dispatch(selectedBuildingsUpdated(selectedBuildings));
  };

  const customCompareValue = (option: ParkViewModel.SelectOption, value: ParkViewModel.SelectOption) => {
    return option.value === value.value && option.type === value.type && option.label === value.label;
  };

  return {
    t,
    options,
    onChangeHandler,
    customCompareValue
  };
}