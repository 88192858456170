import axios, {AxiosHeaders} from "axios";
import {HttpResponse, IHttpGateway} from "@src/core/ports/http.gateway";

export class AxiosGateway implements IHttpGateway {
  constructor() {
    axios.defaults.withCredentials = true;
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.interceptors.request.use(function (config) {
      if (config.url.includes("/csrf/") || !["post", "put", "patch", "delete"].includes(config.method.toLowerCase())) {
        return config;
      }
      return axios.get("/csrf/").then((response) =>
        Object.assign<typeof config, typeof config>(config, {
          headers: new AxiosHeaders({
            ...config.headers,
            "X-CSRFToken": response.data,
          }),
        })
      );
    });
  }

  async get<T>(url: string): Promise<HttpResponse<T>> {
    const {data, status} = await axios.get<T>(url);
    return {data, status};
  }

  async post<T>(url: string, payload: any): Promise<HttpResponse<T>> {
    const {data, status} = await axios.post<T>(url, payload);
    return {data, status};
  }
}