import { FormControlLabel, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ReactSelect from "react-select";

import axios from "axios";

import {
  selectSensors,
  setShouldFetchSensors,
  period as storePeriod,
  sensors as storeSensors,
  switchTemperatureDisplay
} from "@src/store/reducers/metrology";

import { Mode, Sensor, SensorInformation} from "@src/common";
import DateRangePicker from "@src/common/daterangepicker";
import Select from "@src/common/select";
import { RootState } from "@src/store";
import {
  PlainButton
} from "@src/dashboards/comfort/components/buttons/button-export";
import {
  getFloorNumber
} from "@src/metrology/utils/sort_sensors";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Thresholds from "./threshold";
import {useFilters} from "@src/metrology/components/filters/use-filters";
import {MetrologyDomainModel} from "@src/metrology/core/models/equipments";
import {useTranslation} from "react-i18next";


export default function Filters() {
  const { t, i18n } = useTranslation("metrology");
  const presenter = useFilters();

  const translations = {
    capt: t("Sensors and CVC systems"),
    pdl: t("Delivery Point"),
    sub: t("Sub meter"),
    default: t("Sensors"),
    not_categorized: t("Not Categorized"),
  };

  const [params] = useSearchParams();
  const [types, setTypes] = useState([]);
  const [typesOptionAvailable, setTypesOptionAvailable] = useState<{ label: string, value: string }[]>();
  const [typesOptionSelected, setTypesOptionSelected] = useState<{ label: string, value: string }[]>();
  const [floorOptionAvailable, setFloorOptionAvailable] = useState<{ label: string, value: string }[]>();
  const [sensorsOptionSelected, setSensorsOptionSelected] = useState<{ [prop: string]: { label: string, value: string, color?: string; type?: string }[] }>({
    default: [],
    pdl: [],
    sub: [],
    capt: [],
    not_categorized: [],
  });

  const [clearableSelect, setClearableSelect] = useState<{ [key: string]: boolean }>({
    pdl: true, sub: true, capt: true
  });

  const [
    waitingSensorSelected, setWaitingSensorSelected
  ] = useState<{ [prop: string]: Sensor[] }>({});

  const sensors = useSelector((state: RootState) => state.metrology.sensors);
  const mode = useSelector((state: RootState) => state.metrology.mode);
  const start = useSelector((state: RootState) => state.metrology.start);
  const end = useSelector((state: RootState) => state.metrology.end);
  const selectedSensors = useSelector((state: RootState) => state.metrology.selectedSensors);
  const alreadyFetchedSensors = useSelector((state: RootState) => state.metrology.alreadyFetchedSensors);
  // const remoteTemperatureDisplay = useSelector((state: RootState) => state.metrology.remoteTemperatureDisplay);

  const dispatch = useDispatch();

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);

  const optionTypes = (_sensors: {[prop: string]: Sensor[]} | null = null, field = "type") => {
    _sensors = _sensors === null ? sensors : _sensors;
    return Array.from(
      new Set(
        Object.values(_sensors || {})
          .flat(1)
          .map((sensor) => sensor.gui_informations[field as keyof typeof sensor.gui_informations])
      )
    ).map((type) => ({
      value: type,
      label: type,
    }));
  };

  useEffect(() => {
    if (!sensors && mode) {
      axios
        .get(
          `/api/v1/${mode}/sensor?building=${params.get("building")
          }&type=${mode
          }&startDate=${moment(start).format("YYYY-MM-DD HH:mm:ss")
          }&endDate=${moment(end).format("YYYY-MM-DD HH:mm:ss")}`
        )
        .then((res) => {
          res.data?.default?.sort(function(a: Sensor, b: Sensor) {
            const floor = getFloorNumber(a.gui_informations.etage);
            const floor2 = getFloorNumber(b.gui_informations.etage);
            return floor - floor2;
          });

          dispatch(storeSensors(res.data));
          const optionsAvailable = optionTypes(res.data?.default);
          const floorOptionsAvailable = optionTypes(res.data?.default, "etage");
          setTypesOptionAvailable(optionsAvailable);
          setFloorOptionAvailable(floorOptionsAvailable);
          const sensors_ = Object.keys(res.data)
            .reduce((prev, k) => Object.assign(prev, {
              [k]: [].concat(res.data[k]?.[0])
                .filter(s => !!s)
            }), {});
          const options_ = Object.keys(res.data)
            .reduce((prev, k) => Object.assign(prev, {
              [k]: [].concat(res.data[k]?.[0])
                .filter(s => !!s).map(s => {
                  return {
                    value: s.name,
                    label: s.gui_informations.label,
                    color: s.color,
                    type: k,
                  };
                })
            }), {});
          setSensorsOptionSelected(options_);
          dispatch(selectSensors({ ...sensors_ }));
          setWaitingSensorSelected({ ...sensors_ });
        });
    }
  }, [sensors, mode]);

  const options = (type: keyof Sensor | string) => {
    const _tmp = sensors[type].filter(s => presenter.filter.isMatchingCriteria(s));

    return _tmp.map((sensor) => ({
      value: sensor.name,
      label: sensor.gui_informations.label,
      color: sensor.color,
      type: type,
    }));
  };

  useEffect(() => {
    if (!sensors && mode) {
      axios
        .get(
          `/api/v1/${mode}/sensor?building=${params.get("building")
          }&type=${mode
          }&startDate=${moment(start).format("YYYY-MM-DD HH:mm:ss")
          }&endDate=${moment(end).format("YYYY-MM-DD HH:mm:ss")}`
        )
        .then((res) => {
          res.data?.default?.sort(function (a: Sensor, b: Sensor) {
            const floor = getFloorNumber(a.gui_informations.etage);
            const floor2 = getFloorNumber(b.gui_informations.etage);
            return floor - floor2;
          });

          dispatch(storeSensors(res.data));
          setTypesOptionAvailable(optionTypes(res.data?.default));
          const sensors_: { [prop: string]: Sensor[] } = Object.keys(res.data)
            .reduce((prev, k) => Object.assign(prev, {
              [k]: [].concat(res.data[k]?.[0])
                .filter(s => !!s)
            }), {});
          dispatch(selectSensors({ ...sensors_ }));
          setWaitingSensorSelected({ ...sensors_ });
        });
    }
  }, [sensors, mode]);

  const values = (_types: string[], field = "type") => {
    if (!selectedSensors?.default || Object.keys(selectedSensors?.default).length === 0) {
      return [];
    }
    let _tmp = selectedSensors.default;
    if (_types.length > 0) {
      _tmp = _tmp.filter((sensor) => {
        return _types.includes(sensor.gui_informations[field as keyof typeof sensor.gui_informations]);
      });
    }

    return _tmp.map((sensor) => ({
      value: sensor.name,
      label: sensor.gui_informations.label,
      color: sensor.color,
      type: "default",
    }));
  };

  const onSelectAllTypes = () => {
    const options = optionTypes();
    setTypes(options.map((o) => o.value));
    setTypesOptionSelected(options);
  };

  const onSelectAllSensorBy = (type: keyof Sensor) => {
    const newOptionSelected = options(type);
    const newSensorsSelection = {
      ...selectedSensors,
      [type]: newOptionSelected.map(
        (o) => sensors[type].find(sensor => sensor.name === o.value)
      )
    };

    const shouldFetch = newSensorsSelection[type]?.some(
      sensor => !alreadyFetchedSensors.includes(sensor.name)
    );
    dispatch(setShouldFetchSensors(shouldFetch));
    dispatch(selectSensors(newSensorsSelection));
    setSensorsOptionSelected({ ...sensorsOptionSelected, [type]: newOptionSelected });
  };

  const onUnselectAllTypes = () => {
    setTypesOptionSelected([]);
  };

  const onUnselectAllSensorBy = (type: keyof Sensor) => {
    setSensorsOptionSelected({ ...sensorsOptionSelected, [type]: [] });
    dispatch(selectSensors({ ...selectedSensors, [type]: [] }));
  };

  const handleTemperatureButton = () => {
    dispatch(switchTemperatureDisplay());
  };

  return (
    <div className="card">
      <div className="card-body" style={{padding: "0.5rem"}}>
        <div>
          <h6 className="card-title mb-1" style={{textAlign: "center"}}>
            {t("Filters")}
          </h6>
        </div>
        <div className="panel-filters-metrologie" id="filterMetrologie">
          <div className="panel panel-default mb-2">
            <div className="panel-heading1">
              <h4 className="panel-title1">
                <a
                  className="accordion-toggle"
                  data-toggle="collapse"
                  data-parent="#filterMetrologie"
                  href="#collapsePeriod"
                  aria-expanded="false"
                >
                  {t("Periods")}
                </a>
              </h4>
            </div>
          </div>
          <div
            id="collapsePeriod"
            className="panel-collapse collapse show"
            role="tabpanel"
            aria-expanded="false"
          >
            <div className="panel-body" style={{marginBottom: ".5rem"}}>
              <div className="col">
                <DateRangePicker
                  id="comfort-filter-date"
                  start={moment(start).startOf("day")}
                  end={moment(end)}
                  onChange={(start, end) => {
                    dispatch(setShouldFetchSensors(true));
                    dispatch(storePeriod({ start, end }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="panel panel-default mb-0">
          <div
            className="panel-heading1"
            style={{textAlign: "center", backgroundColor: "#242f48", borderRadius: "6px"}}
          >
            <h4 className="panel-title1">
              <a
                className="accordion-toggle mb-0 collapsed"
                data-toggle="collapse"
                data-parent="#filterMetrologie"
                href="#pointMeasure"
                aria-expanded="false"
              >
                {t("Measurement points")}
              </a>
            </h4>
          </div>
          <div
            id="pointMeasure"
            className="panel-collapse collapse show"
            role="tabpanel"
            aria-expanded="false"
          >
            <div className="panel-body" style={{padding: "0"}}>
              <div className="card-body" style={{padding: ".7rem"}}>
                <div className="row row-sm">
                  <div className="col d-flex justify-content-center">
                    <FormControlLabel
                      control={<Switch name="remote_temperature" onChange={handleTemperatureButton} />}
                      label={t("Outdoor temperature")}
                    />
                  </div>
                </div>

                {mode === Mode.COMFORT ?
                  (
                    <div className="row row-sm">
                      <div className="card col">
                        <div
                          className="card-body"
                          id="sensorsType"
                          style={{textAlign: "center"}}>
                          <div className="mb-3">
                            <h6>{t("Room Type")}</h6>
                            <ReactSelect
                              placeholder={t("Select")}
                              isMulti={true}
                              options={presenter.options.types}
                              noOptionsMessage={() => t("No options available")}
                              closeMenuOnSelect={false}
                              onChange={(selected) => {
                                const values = selected.map(o => o.value);
                                presenter.filter.update(values, "type");
                                const newSensorsSelection = selectedSensors?.default?.filter(
                                  (s) => presenter.filter.isMatchingCriteria(s)
                                );

                                const newSensorsOptionsSelected = sensorsOptionSelected?.default?.filter(
                                  (option: MetrologyDomainModel.SensorOption) => {
                                    return newSensorsSelection.find(s => s.name === option.value);
                                  }
                                );
                                setSensorsOptionSelected({
                                  ...sensorsOptionSelected,
                                  default: newSensorsOptionsSelected ?? []
                                });
                                dispatch(selectSensors({
                                  ...sensorsOptionSelected,
                                  default: newSensorsSelection ?? []
                                }));
                                setWaitingSensorSelected({
                                  ...sensorsOptionSelected,
                                  default: newSensorsSelection ?? []
                                });
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <h6>{t("Floors")}</h6>
                            <ReactSelect
                              placeholder={t("Select")}
                              isMulti={true}
                              options={presenter.options.etages}
                              noOptionsMessage={() => t("No options available")}
                              closeMenuOnSelect={false}
                              onChange={(selected) => {
                                const values = selected.map(o => o.value);
                                presenter.filter.update(values, "etage");
                                const newSensorsSelection = selectedSensors?.default?.filter(
                                  (s) => presenter.filter.isMatchingCriteria(s)
                                );

                                const newSensorsOptionsSelected = sensorsOptionSelected?.default?.filter(
                                  (option: MetrologyDomainModel.SensorOption) => {
                                    return newSensorsSelection.find(s => s.name === option.value);
                                  }
                                );
                                setSensorsOptionSelected({
                                  ...sensorsOptionSelected,
                                  default: newSensorsOptionsSelected ?? []
                                });
                                dispatch(selectSensors({
                                  ...sensorsOptionSelected,
                                  default: newSensorsSelection ?? []
                                }));
                                setWaitingSensorSelected({
                                  ...sensorsOptionSelected,
                                  default: newSensorsSelection ?? []
                                });
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <h6>{t("Zones")}</h6>
                            <ReactSelect
                              placeholder={t("Select")}
                              noOptionsMessage={() => t("No options available")}
                              isMulti={true}
                              options={presenter.options.zones}
                              closeMenuOnSelect={false}
                              onChange={(selected) => {
                                const values = selected.map(o => o.value);
                                presenter.filter.update(values, "zone");
                                const newSensorsSelection = selectedSensors?.default?.filter(
                                  (s) => presenter.filter.isMatchingCriteria(s)
                                );

                                const newSensorsOptionsSelected = sensorsOptionSelected?.default?.filter(
                                  (option: MetrologyDomainModel.SensorOption) => {
                                    return newSensorsSelection.find(s => s.name === option.value);
                                  }
                                );
                                setSensorsOptionSelected({
                                  ...sensorsOptionSelected,
                                  default: newSensorsOptionsSelected ?? []
                                });
                                dispatch(selectSensors({
                                  ...sensorsOptionSelected,
                                  default: newSensorsSelection ?? []
                                }));
                                setWaitingSensorSelected({
                                  ...sensorsOptionSelected,
                                  default: newSensorsSelection ?? []
                                });
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <h6>{t("Tenants")}</h6>
                            <ReactSelect
                              placeholder={t("Select")}
                              noOptionsMessage={() => t("No options available")}
                              isMulti={true}
                              options={presenter.options.tenants}
                              closeMenuOnSelect={false}
                              onChange={(selected) => {
                                const values = selected.map(o => o.value);
                                presenter.filter.update(values, "tenant");
                                const newSensorsSelection = selectedSensors?.default?.filter(
                                  (s) => presenter.filter.isMatchingCriteria(s)
                                );

                                const newSensorsOptionsSelected = sensorsOptionSelected?.default?.filter(
                                  (option: MetrologyDomainModel.SensorOption) => {
                                    return newSensorsSelection.find(s => s.name === option.value);
                                  }
                                );
                                setSensorsOptionSelected({
                                  ...sensorsOptionSelected,
                                  default: newSensorsOptionsSelected ?? []
                                });
                                dispatch(selectSensors({
                                  ...sensorsOptionSelected,
                                  default: newSensorsSelection ?? []
                                }));
                                setWaitingSensorSelected({
                                  ...sensorsOptionSelected,
                                  default: newSensorsSelection ?? []
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                  :
                  (
                    <div className="row row-sm">
                      <div className="card col">
                        <div
                          className="card-body text-center pl-1 pr-1"
                          id="sensorsType"
                        >
                          <h6>{t("Room type")}</h6>
                          <ReactSelect
                            placeholder={t("Select")}
                            isMulti={true}
                            options={typesOptionAvailable}
                            noOptionsMessage={() => t("No options available")}
                            value={typesOptionSelected}
                            closeMenuOnSelect={false}
                            onChange={(selected) => {
                              const newTypes = selected.map((s) => s.value);
                              setTypes(newTypes);
                              setTypesOptionSelected(selected.map(s => s));
                              const newSensorsSelectionOptions = values(newTypes);
                              setSensorsOptionSelected({
                                ...sensorsOptionSelected,
                                default: newSensorsSelectionOptions
                              });
                              const newSensorsSelection = selectedSensors?.default?.filter(
                                s => !!selected.find(o => o.value === s.gui_informations.type)
                              );
                              dispatch(selectSensors({...newSensorsSelection}));
                            }}
                          />
                          <div className="metrology-buttons mt-1">
                            <PlainButton onClickHandler={onSelectAllTypes}
                              text={t("Select all")}/>
                            <PlainButton onClickHandler={onUnselectAllTypes}
                              text={t("Unselect all")}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {Object.keys(sensors || {}).map((k) => {
                  return (
                    <div key={"filters-" + k} className="row row-sm">
                      <div className="card col">
                        <div className="card-body text-center pl-1 pr-1">
                          <h6>
                            {translations[k as keyof typeof translations]}
                          </h6>
                          <Select
                            isClearable={clearableSelect[k]}
                            placeholder={t("Select")}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            onFocus={() => {
                              setClearableSelect({
                                pdl: false,
                                sub: false,
                                capt: false,
                                [k]: true
                              });
                            }}
                            onBlur={() => {
                              setClearableSelect({
                                pdl: true,
                                sub: true,
                                capt: true
                              });
                            }}
                            onMenuClose={() => {
                              const shouldFetch = waitingSensorSelected[k]?.some(
                                sensor => !alreadyFetchedSensors.includes(sensor.name)
                              );
                              dispatch(setShouldFetchSensors(shouldFetch));
                              dispatch(selectSensors({ ...waitingSensorSelected }));
                            }}
                            onChange={(selected) => {
                              setSensorsOptionSelected({
                                ...sensorsOptionSelected,
                                [k]: selected.map(s => s)
                              });
                              setWaitingSensorSelected(
                                {
                                  ...selectedSensors,
                                  [k]: selected.map((s) => sensors[k].find(sensor => sensor.name === s.value))
                                }
                              );
                            }}
                            value={sensorsOptionSelected[k as keyof Sensor] as any}
                            options={options(k as keyof Sensor)}
                            // defaultValue={options(k as keyof Sensor)?.[0]}
                          />
                          <div
                            className="metrology-buttons mt-1"
                          >
                            <PlainButton
                              onClickHandler={() => onSelectAllSensorBy(k as keyof Sensor)}
                              text={t("Select all")} />
                            <PlainButton
                              onClickHandler={() => onUnselectAllSensorBy(k as keyof Sensor)}
                              text={t("Unselect all")} />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {mode === Mode.COMFORT && <Thresholds />}
        </div>
      </div>
    </div >
  );
}
