import { useEffect } from "react";
import {useTranslation} from "react-i18next";
import * as L from "leaflet";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { RootState } from "@src/store";
import { ParkDomainModel } from "@src/dashboards/parks/core/models/park.domain-model";
import { isProduction } from "@src/common/utils";

export function useMapOfBuildingsPresenter() {
  const {t, i18n} = useTranslation("dashboardPark");
  const [params] = useSearchParams();
  const selectedBuildings = useSelector((state: RootState) => state.parkDashboard.selectedBuildings);

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);


  const baseImgUrl = isProduction() ? "/img/" : "/static/img/";
  const DefaultIcon = L.icon({
    iconUrl: baseImgUrl + "marker-icon.png",
    shadowUrl: baseImgUrl + "marker-shadow.png"
  });
  
  const formattedBuildings = selectedBuildings
    .filter(building => building.informations.latitude && building.informations.longitude)
    .map((building: ParkDomainModel.Building) => {
      const {latitude, longitude} = building.informations;
      const url =`/dashboard/building?parc=${params.get("parc")}&building=${building.id}`;
      return {
        position: [latitude, longitude], 
        url, 
        name: building.name, 
        id: building.id, 
        address: building.address
      };
    });


  return {
    t,
    DefaultIcon,
    buildings: formattedBuildings
  };
}
