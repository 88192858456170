import { Switch } from "@mui/material";
import _ from "lodash";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { thresholds as storeThresholds } from "@src/store/reducers/metrology";
import { RootState } from "@src/store";
import { useTranslation } from "react-i18next";
import { PlainButton } from "@src/dashboards/comfort/components/buttons/button-export";
import axios from "axios";
import { useSearchParams } from "react-router-dom";


export default function Thresholds() {
  const [params] = useSearchParams();
  const { t } = useTranslation("metrology");

  const translations = {
    winter: t("Winter"),
    summer: t("Summer"),
    annual: t("Yearly"),
    luminosity: t("Yearly"),
  };

  const thresholds = useSelector(
    ({ metrology: { thresholds } }: RootState) => thresholds
  );
  
  const dispatch = useDispatch();

  const {temperature, luminosity, humidity, air_quality} = thresholds;

  const categories = {
    temperature: {
      set: (value: typeof temperature) => {
        dispatch(storeThresholds(Object.assign({}, thresholds, {temperature: value})));
      },
      values: _.cloneDeep(temperature),
      translate: t("Temperature"),
    },
    humidity: {
      set: (value: typeof humidity) => {
        dispatch(storeThresholds(Object.assign({}, thresholds, {humidity: value})));
      },
      values: _.cloneDeep(humidity),
      translate: t("Humidity"),
    },
    air_quality: {
      set: (value: typeof air_quality) => {
        dispatch(storeThresholds(Object.assign({}, thresholds, {air_quality: value})));
      },
      values: _.cloneDeep(air_quality),
      translate: t("CO2 Rate"),
    },
    luminosity: {
      set: (value: typeof luminosity) => {
        dispatch(storeThresholds(Object.assign({}, thresholds, {luminosity: value})));
      },
      values: _.cloneDeep(luminosity),
      translate: t("Luminosity"),
    },
  };
  
  const onSaveHandler = () => { 
    axios.patch(`/api/v1/buildings/${params.get("building")}/metrology/thresholds`, {thresholds})
      .then()
      .catch((err) => {
        console.error("Error: ", err);  
      });
  };

  return (
    <div className="panel panel-default mt-3 mb-0">
      <div
        className="panel-heading1"
        style={{
          textAlign: "center",
          backgroundColor: "#242f48",
          borderRadius: "6px"
        }}
      >
        <h4 className="panel-title1">
          <a
            className="accordion-toggle mb-0 collapsed"
            data-toggle="collapse"
            data-parent="#filterMetrologie"
            href="#thresholds"
            aria-expanded="false"
          >
            {t("Thresholds")}
          </a>
        </h4>
      </div>
      <div
        id="thresholds"
        className="panel-collapse collapse"
        role="tabpanel"
        aria-expanded="false"
        data-category="{{ category }}"
      >
        <div className="panel-body" style={{padding: 0}}>
          <div className="card-body" style={{padding: ".7rem"}}>
            <table className="table table-borderless table-sm">
              <thead>
                <tr>
                  <th>{t("Period")}</th>
                  <th>{t("Min")}</th>
                  <th>{t("Max")}</th>
                  <th>{t("Activate")}</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(categories).map(([categoryKey, category]) => (
                  <Fragment key={categoryKey}>
                    <tr>
                      <td colSpan={5} style={{ textAlign: "center" }}>
                        {category.translate}
                      </td>
                    </tr>
                    {Object.entries(category.values).map(([key, value]) => {
                      return (
                        <tr key={`${categoryKey}-${key}`}>
                          <td>
                            {
                              translations[
                                key as keyof typeof category.values
                              ] as string
                            }
                          </td>
                          <td>
                            <input
                              value={value.min}
                              className="form-control form-control-sm"
                              onChange={(e) => {
                                const temp = Object.assign(
                                  {},
                                  category.values
                                ) as any;
                                temp[key].min = parseInt(e.target.value);
                                category.set(temp);
                              }}
                              type="number"
                              step={1}
                              min={0}
                            />
                          </td>
                          <td>
                            <input
                              value={value.max}
                              className="form-control form-control-sm"
                              onChange={(e) => {
                                const temp = Object.assign(
                                  {},
                                  category.values
                                ) as any;
                                temp[key].max = parseInt(e.target.value);
                                category.set(temp);
                              }}
                              type="number"
                              step={1}
                              min={0}
                            />
                          </td>
                          <td>
                            <Switch
                              onChange={() => {
                                const temp = Object.assign(
                                  {},
                                  category.values
                                ) as any;
                                temp[key].active = !temp[key].active;
                                category.set(temp);
                              }}
                              checked={
                                !!(category.values as any)[key]?.active as boolean
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </Fragment>
                ))}
              </tbody>
            </table>
            <div>
              <PlainButton text={t("Save")} onClickHandler={onSaveHandler} klass="m-auto"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface Props {
  setThresholds(thresholds: any): void;
}
