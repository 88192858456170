import { useDetailCardPresenter } from "./use-detail-card-presenter";


export default function DetailCard() {
  const presenter = useDetailCardPresenter();
  const { t } = presenter;  

  return (
    <div className="card card-dashboard-eight pb-2 park-card">
      <h6 className="card-title text-center">{t("Park Details")}</h6>
      <div className="list-group border-top-0">
        <div className="list-group-item border-top-0" id="br-t-0">
          <p>{t("Total surface building cluster")}</p>
          <span className="total_surface">{presenter.detail.totalSurface}</span>
        </div>
        <div className="list-group-item mb-0">
          <p>{t("Number of buildings")}</p>
          <span className="number_of_buildings">{presenter.detail.numberOfBuildings}</span>
        </div>
        <div className="list-group-item mb-0">
          <p>{t("Type of activities")}</p>
          <span className="main_activities">{presenter.detail.activitiesType}</span>
        </div>
        <div className="list-group-item mb-0">
          <p>{t("Type of sensors")}</p>
          <span className="sensor_type">{presenter.detail.sensorsType}</span>
        </div>
        <div className="list-group-item border-bottom-0 mb-0">
          <p>{t("Number of capteurs")}</p>
          <span className="number_of_sensors">{presenter.detail.numberOfSensors}</span>
        </div>
        <div className="list-group-item border-bottom-0 mb-0">
          <div
            id="update-identity-record"
            className={`page-item rounded m-auto ${!presenter.link ? "disabled" : ""}`}
          >
            <a 
              href={presenter.link} 
              target="_blank" 
              className="page-link" 
              rel="noreferrer" 
              title={!presenter.link ? t("No identity record available") : ""}
            >
              {t("Update identity record")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

