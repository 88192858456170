import { useTotalIndicatorPresenter } from "./use-total-indicator-presenter";

type IndicatorUnit = "MWh" | "tCO₂eq" | "€ HT";

type Props = {
    title: string;
    unit: IndicatorUnit;
    value: number;
    color: string;
}

export default function TotalIndicator({title, value, unit, color}: Props) {
  const presenter = useTotalIndicatorPresenter(value);
  const { t } = presenter;

  return (
    <div className={`card overflow-hidden sales-card ${color}`}>
      <div className="ps-3 pt-3 pe-3 pb-2 pt-0 total-headers">
        <div className="">
          <h6 className="mb-3 tx-16 text-white">
            {t(title)}
          </h6>
        </div>
        <div className="pb-0 mt-0">
          <div className="d-flex">
            <div className="" id="parc-total-consumption">
              <h6 className="no-data-btn text-white tx-22">{presenter.formattedValue} {unit}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}