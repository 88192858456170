import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import {
  MetrologyAllData,
  MetrologyEnergyLoading,
  MetrologyOpenChart,
  MetrologyResponse,
  Mode,
  Sensor,
  Thresholds,
  ListComfortDataBySensor, 
  ListEnergyDataBySensor,
  NewSensorsData,
  actionDispatch
} from "../../common";

const initialState: MetrologyContext = {
  start: moment().startOf("day").subtract(7, "days").valueOf(),
  end: moment().valueOf(),
  comfortDataBySensor: {},
  energyDataBySensor: {elec: {}, hot: {}, cold: {}, capt: {}},
  sensors: null,
  alreadyFetchedSensors: [],
  selectedSensors: {},
  shouldFetchSensors: true,
  mode: null,
  thresholds: {
    temperature: {summer: { min: 22, max: 26, active: true }, winter: { min: 19, max: 21, active: true }},
    humidity: { summer: { min: 40, max: 60, active: true }, winter: { min: 40, max: 60, active: true } },
    air_quality: { annual: { min: 0, max: 0, active: true } },
    luminosity: { luminosity: { min: 0, max: 0, active: true } },
  },
  dataIsLoading: {elec: false, hot: false, cold: false, capt: false},
  openCharts: {
    comfort: false,
    batterie: true,
    brightness: true,
    co2: true,
    humidity: true,
    motion: true,
    temperature: true,
    cold: false,
    hot: false,
    elec: true,
    capt: false,
  },
  headerIsLoading: {elec: false, hot: false, cold: false, capt: false},
  header: {},
  displayFormat: {elec: "min", hot: "min", cold: "min", capt: "min"},
  remoteTemperatureDisplay: false,
  dateIsChanging: false,

};

export const metrologySlice = createSlice({
  name: "metrology",
  initialState,
  reducers: {
    period(state, action) {
      const {
        payload: { end, start },
      } = action;

      state.end = end;
      state.start = start;
    },
    sensors(state, action) {
      state.sensors = action.payload;
    },
    setAlreadyFetchedSensors(state, action) {
      state.alreadyFetchedSensors = action.payload;
    },
    setShouldFetchSensors(state, action) {
      state.shouldFetchSensors = action.payload;
    },
    setHeaderByType(state, action) {
      const energy = action.payload.eng;
      state.header[energy] = action.payload.header;
    },
    setDataIsLoadingByType(state, action: actionDispatch) {
      state.dataIsLoading[action.payload.eng] = action.payload.bool;
    },
    setDataIsLoadingComfort(state, action) {
      const types = ["capt", "temperature", "humidity", "co2", "batterie", "motion", "brightness", "comfort"];
      types.forEach((type) => {
        state.dataIsLoading[type] = action.payload.value;
      });
    },
    setHeaderIsLoadingByType(state, action: actionDispatch) {
      state.headerIsLoading[action.payload.eng] = action.payload.bool;
    },
    setOpenCharts(state, action) {
      const type = action.payload.type as keyof typeof state.openCharts;
      state.openCharts[type] = action.payload.value;
    },
    selectSensors(state, action) {
      state.selectedSensors = action.payload;
    },
    mode(state, action) {
      state.mode = action.payload;
    },
    thresholds(state, action) {
      state.thresholds = action.payload;
    },
    format(state, action) {
      state.displayFormat[action.payload.eng as keyof typeof state.displayFormat] = action.payload.period;
    },
    updateDataBySensor(state, action: actionDispatch) {
      const stateToUpdate = action.payload.mode == Mode.COMFORT ? state.comfortDataBySensor : state.energyDataBySensor[action.payload.eng];

      action.payload.data.map((sensorData: NewSensorsData) => {
        stateToUpdate[sensorData.deveui] = {display: true, sensor: sensorData};
      });
    },
    resetDataBySensor(state, action: actionDispatch) {
      if (action.payload.mode == Mode.COMFORT) {state.comfortDataBySensor = {};} 
      else {state.energyDataBySensor[action.payload.eng] = {};}
    },
    switchSensorDisplay(state, action: actionDispatch) {
      const stateToUpdate = action.payload.mode == Mode.COMFORT ? state.comfortDataBySensor : state.energyDataBySensor[action.payload.eng];
      if (!stateToUpdate[action.payload.sensorLabel]) {return;}
      stateToUpdate[action.payload.sensorLabel].display = action.payload.bool;
    },
    switchTemperatureDisplay(state) {
      state.remoteTemperatureDisplay = !state.remoteTemperatureDisplay;
    },
    setDateIsChanging(state, action) {
      state.dateIsChanging = action.payload;
    },
  },
});

export const {
  period, sensors, selectSensors, mode, thresholds, 
  setShouldFetchSensors, setAlreadyFetchedSensors, 
  format,setDataIsLoadingByType, setHeaderByType, 
  setHeaderIsLoadingByType, updateDataBySensor, 
  resetDataBySensor, switchSensorDisplay, 
  switchTemperatureDisplay, setDateIsChanging, 
  setOpenCharts, setDataIsLoadingComfort
} = metrologySlice.actions;

export default metrologySlice.reducer;

interface MetrologyContext {
  start: number;
  end: number;
  comfortDataBySensor: ListComfortDataBySensor
  energyDataBySensor: ListEnergyDataBySensor
  sensors: {[prop: string]: Sensor[]};
  selectedSensors: {[prop: string]: Sensor[]};
  alreadyFetchedSensors: string[];
  shouldFetchSensors: boolean;
  mode: Mode;
  thresholds: Thresholds | null;
  dataIsLoading: MetrologyEnergyLoading;
  openCharts: MetrologyOpenChart;
  headerIsLoading: MetrologyEnergyLoading;
  header: any,
  displayFormat: {
    elec: string;
    hot: string;
    cold: string;
    capt: string;
  }
  remoteTemperatureDisplay: boolean
  dateIsChanging: boolean
}