import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {
  authTranslation, metrologyTranslation, dashboardTranslation,
  commonTranslation, dashboardParkTranslation
} from "@src/app/locales/fr";

const resources = {
  fr: {
    common: commonTranslation,
    auth: authTranslation,
    metrology: metrologyTranslation,
    dashboard: dashboardTranslation,
    dashboardPark: dashboardParkTranslation,
  }
};

i18n
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    ns: ["common", "auth", "metrology", "dashboard", "dashboardPark"],
    defaultNS: "common",
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;