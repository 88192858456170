export default {
  "Select a year": " Sélectionenr une année",
  "Total consumption": "Consommation totale",
  "Expenses": "Dépenses",
  "Emissions": "Emissions",
  "Saving": "Économies",
  "No data available": "Pas de Donnée",
  "Park Details": "Détails du parc",
  "Total surface building cluster": "Surface totale du parc",
  "Number of buildings": "Nombre de bâtiments",
  "Type of activities": "Type des activités",
  "Type of sensors": "Type des capteurs",
  "Number of capteurs": "Nombre de capteurs",
  "Update identity record": "Modifier la fiche identité",
  "No identity record available": "Aucune fiche identité disponible",
  "Filters": "Filtres",
  "Before 1974": "Avant 1974",
  "Between 1974 and 1988": "Entre 1974 et 1988",
  "Between 1988 and 2000": "Entre 1988 et 2000",
  "Between 2000 and 2005": "Entre 2000 et 2005",
  "Between 2005 and 2012": "Entre 2005 et 2012",    
  "After 2012": "Après 2012",
  "Not referenced": "Non référencé",
  "Select a region": "Sélectionner une région",
  "Network": "Réseau",
  "Select a network": "Sélectionner un réseau",
  "Construction year": "Année de construction",
  "Main activity": "Activité principale",
  "Select an activity": "Sélectionner une activité",
  "Buildings map": "Carte des bâtiments",
  "Building distribution":"Répartitions des bâtiments",
  "Energy consumption ranking": "Bâtiments énergivores",
  "CO2 emission ranking": "Bâtiments polluants",
  "Expenses ranking": "Bâtiments couteux",
  "Show more": "Voir plus",
  "Show less": "Voir moins",
  "Building synthesis": "Synthèse des bâtiments",
  "Building": "Bâtiment",
  "Construction": "Construction",
  "Electricity": "Electricité",
  "Hot Network": "Réseau de chaud",
  "Total cost": "Cout total",
  "Previous": "Précédent",
  "Next": "Suivant",
  "DPE": "DPE",
  "GES": "GES",
  "City": "Ville",
  "kWh EF/m²/year": "kWh EF/m²/an",
  "Median": "Médiane",
  "Average": "Moyenne",
  "of": "sur",
  "Rows per page": "Lignes par page"
};
