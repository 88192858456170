import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {
  dashboardReducer, metrologyReducer,
  costicReducer, authReducer,
  parkDashboardReducer
} from "./reducers";
import {Dependencies} from "@src/app/dependencies";
import {useDispatch} from "react-redux";

const reducers = combineReducers({
  auth: authReducer,
  metrology: metrologyReducer,
  dashboard: dashboardReducer,
  costic: costicReducer,
  parkDashboard: parkDashboardReducer
});

export const createStore = (config: {
    initialState?: RootState;
    dependencies: Dependencies;
}) => {
  const store = configureStore({
    preloadedState: config?.initialState,
    reducer: reducers,
    devTools: true,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        thunk: {
          extraArgument: config.dependencies,
        },
      });
    },
  });

  return store;
};

export type AppStore = ReturnType<typeof createStore>;
export type RootState = ReturnType<typeof reducers>;
export type AppDispatch = AppStore["dispatch"];
export type AppGetState = AppStore["getState"];

export const useAppDispatch = () => useDispatch<AppDispatch>();