import {ParkDomainModel} from "@src/dashboards/parks/core/models/park.domain-model";
import {createSlice} from "@reduxjs/toolkit";
import {fetchPark} from "@src/dashboards/parks/core/usecases/fetch-park.usecase";
import {fetchConsumption} from "@src/dashboards/parks/core/usecases/fetch-consumption.usecase";


const initialState: ParkDomainModel.State = {
  selectedBuildings: [],
  detail: {
    status: "idle",
    statusCode: null,
    error: null,
    data: null
  },
  consumption: {
    status: "idle",
    statusCode: null,
    error: null,
    data: null
  }
};

export const parkDashboardSlice = createSlice({
  name: "parkDashboard",
  initialState,
  reducers: {
    fetchParkFailed(state, action) {
      state.detail.statusCode = action.payload;
    },
    fetchConsumptionFailed(state, action) {
      state.consumption.statusCode = action.payload;
    },
    selectedBuildingsUpdated(state, action) {
      state.selectedBuildings = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPark.pending, (state, _) => {
        state.detail.status = "pending";
        state.detail.error = null;
        state.detail.statusCode = null;
      })
      .addCase(fetchPark.fulfilled, (state, action) => {
        state.detail.status = "succeeded";
        state.detail.data = action.payload;
        state.selectedBuildings = action.payload?.buildings ?? [];
      })
      .addCase(fetchPark.rejected, (state, action) => {
        state.detail.status = "rejected";
        state.detail.error = action.error.message ?? "Unknown Error";
      })
      .addCase(fetchConsumption.pending, (state, _) => {
        state.consumption.status = "pending";
        state.consumption.error = null;
        state.consumption.statusCode = null;
      })
      .addCase(fetchConsumption.fulfilled, (state, action) => {
        state.consumption.status = "succeeded";
        state.consumption.data = action.payload;
      })
      .addCase(fetchConsumption.rejected, (state, action) => {
        state.consumption.status = "rejected";
        state.consumption.error = action.error.message ?? "Unknown Error";
      });
  }
});


export const {
  fetchParkFailed, fetchConsumptionFailed, 
  selectedBuildingsUpdated
} = parkDashboardSlice.actions;

export default parkDashboardSlice.reducer;