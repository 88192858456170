import { useTranslation } from "react-i18next";
import { useConsumption } from "@src/dashboards/parks/react/sections/classifications/use-consumption";

export function useExpensiveConsumptionPresenter() {
  const {t, i18n} = useTranslation("dashboardPark");
  const { handleShowMore, consumptionData, buttonText } = useConsumption("expenses_vat_excl");

  return {
    t,
    i18n,
    handleShowMore,
    consumptionData,
    buttonText,
  };
}
