import { EnergyConsumption } from "./consumption/EnergyConsumption";
import { EmissionConsumption } from "./emissions/EmissionConsumption";
import { ExpensiveConsumption } from "./expensive/ExpensiveConsumption";

export function BuildingClassification() {
  return (
    <>
      <div className="col-4">
        <EnergyConsumption />
      </div>
      <div className="col-4">
        <EmissionConsumption />
      </div>
      <div className="col-4">
        <ExpensiveConsumption />
      </div>
    </>
  );
}