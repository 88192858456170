import {IDateProvider} from "@src/core/ports/date.provider";
import moment from "moment";


export class SystemDateProvider implements IDateProvider {
  getNow() {
    return moment().toDate();
  }

  subtract(date: Date, amount: number, unit: string) {
    return moment(date).subtract(amount, unit as moment.unitOfTime.DurationConstructor).toDate();
  }

  generateDatesFromYear(year: string) {
    if (year === "default") {
      const today = moment();
      const oneYearAgo = moment().subtract(1, "year");
      
      return {
        dateFrom: oneYearAgo.format("YYYY-MM-DD"),
        dateTo: today.format("YYYY-MM-DD")
      };
    }
  
    return {
      dateFrom: `${year}-01-01`,
      dateTo: `${year}-12-31`
    };
  }
}
