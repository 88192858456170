import { useEffect, useState } from "react";
import { MultiValue } from "react-select";
import { Building } from "@src/common/api";
import Select, { SelectOption } from "@src/common/select";
import {useTranslation} from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";

interface Props {
    regions: string[];
    buildings: Building[];
    onSelectBuilding: (buildings: Building[]) => void;
}

export default function BuildingFilters(props: Props) {
  const {t, i18n} = useTranslation("dashboard");

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);
  
  const {regions, buildings, onSelectBuilding} = props;
  const regionsOption: MultiValue<SelectOption> = regions?.map(region => {
    return {label: region, value: region?.toLowerCase(), color: "#000000", type: ""};
  });
    
  const [buildingOptions, setBuildingOption] = useState<MultiValue<SelectOption>>([]);

  // Defined Selected Region  in HTML select
  const [selectedRegionOption, setSelectedRegionOption] = useState<SelectOption>(
    regionsOption[0]
  );
    // Defined Selected Buildings in HTML select
  const [selectedBuildingOption, setSelectedBuildingOption] = useState<MultiValue<SelectOption>>(
  );

  const resetFilter = () => {
    setBuildingOption(buildings.map(building => {
      return {label: building.name, value: building.name.toLowerCase(), color: "#000000", type: ""};
    }));
    setSelectedBuildingOption([]);
    onSelectBuilding(buildings);
  };
  const filterBuildingByRegion = (selection: SelectOption) => {
    return buildings.filter(building => {
      return building.informations?.region?.toLowerCase() == selection.value;
    });
  };

  const filterBuildingByName = (selections: MultiValue<SelectOption>) => {
    return buildings.filter(building => {
      return selections.find(
        selected => building.name.toLowerCase() == selected.value
      );
    }
    );
  };

  useEffect(() => {
    const options = buildings?.map(building => {
      return {label: building.name, value: building.name.toLowerCase(), color: "#000000", type: ""};
    });
    setBuildingOption(options);
  }, [buildings]);

  const selectionRegionHandler = (selectedRegion: SelectOption) => {
    setSelectedRegionOption(selectedRegion);
    if (selectedRegion.value?.toLowerCase() === gettext("tous")) {
      resetFilter();
      return;
    }

    const filteredBuildings = filterBuildingByRegion(selectedRegion);
    const filteredOptions = filteredBuildings.map(building => {
      return {label: building.name, value: building.name.toLowerCase(), color: "#000000", type: ""};
    });
    setBuildingOption(filteredOptions);
    setSelectedBuildingOption(filteredOptions);
    onSelectBuilding(filteredBuildings);
  };

  const selectionBuildingHandler = (selectedBuildings: MultiValue<SelectOption>) => {
    setSelectedBuildingOption(selectedBuildings);
    const filteredBuildings = filterBuildingByName(selectedBuildings);
    onSelectBuilding(filteredBuildings);
  };

  return (
    <div>
      <h6>{t("Building")}</h6>
      <div className={"row d-flex align-items-center text-center"}>
        <Select
          placeholder={t("All region")}
          options={regionsOption}
          value={selectedRegionOption}
          onChange={(value: any) => selectionRegionHandler(value)}
          className={"col col-2"}
        />
        <Select
          placeholder={t("Select")}
          isMulti={true}
          options={buildingOptions}
          value={selectedBuildingOption}
          onChange={(values: MultiValue<SelectOption>) => selectionBuildingHandler(values)}
          className={"col col-9"}
        />
        <div className={"col col-1"}>
          <p className="threshold-line">
            {t("Winter")}: 19-21°C
          </p>
          <p className="threshold-line d-flex align-items-center">
            <span>
              {t("Summer")}: 20-26°C
            </span>
            <Tooltip sx={{marginLeft: 1}} title={t("These are the default thresholds by buildings, if no changes have been made to the thresholds on the metrology page")}>
              <InfoIcon color="disabled" fontSize="small"/>
            </Tooltip>
          </p>
          <p className="threshold-line">
            CO<sub>2</sub>: 1 100 ppm
          </p>
        </div>
      </div>
    </div>
  );
}
