import { Thresholds } from "@src/common";
  
export function CustomToolTip({buildingName, threshold, hoverCard}: Props) {
  const winterMin = threshold?.temperature?.winter?.min ?? 19;
  const winterMax = threshold?.temperature?.winter?.max ?? 21;
  const summerMin = threshold?.temperature?.summer?.min ?? 20;
  const summerMax = threshold?.temperature?.summer?.max ?? 26;
  const annualMin = threshold?.air_quality?.annual?.min ?? 0;
  const annualMax = threshold?.air_quality?.annual?.max ?? 1000;
  return (
    <>
      <div className="table-card-hover" ref={hoverCard}>
        <div className="close-icon-container">
          <p></p>
          <p>Seuil du bâtiment&nbsp;:&nbsp;<span><b>{buildingName}</b></span></p>
        </div>
        <div>
          <p><b>En occupation</b></p>
          <p>Hiver&nbsp;:&nbsp;<span>{winterMin}-{winterMax}°C</span></p>
          <p>Été&nbsp;:&nbsp;<span>{summerMin}-{summerMax}°C</span></p>
          <p>CO2&nbsp;:&nbsp;<span>{annualMin}-{annualMax}ppm</span></p>
        </div>
        <br/>
        <div>
          <p><b>En inoccupation</b></p>
          <p>Hiver&nbsp;:&nbsp;<span>16-20°C</span></p>
          <p>Été&nbsp;:&nbsp;<span>Pas de limite</span></p>
          <p>CO2&nbsp;:&nbsp;<span>0-1000ppm</span></p>
        </div>
      </div>
    </>
  );
}

type Props = {
    buildingName: string;
    threshold: Thresholds;
    hoverCard: React.RefObject<HTMLDivElement>;
}