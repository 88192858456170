import { RootState } from "@src/store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export function useDetailCardPresenter() {
  const {t, i18n} = useTranslation(["dashboardPark", "common"]);
  const selectedBuildings = useSelector((state: RootState) => state.parkDashboard.selectedBuildings) ?? [];
  const park_link = useSelector((state: RootState) => state.parkDashboard.detail.data?.identity_record_url);

  let totalSurface = 0;
  let numberOfSensors = 0;
  const activities = new Set<string>();
  const sensorsType = new Set<string>();
  

  for (const building of selectedBuildings) {
    totalSurface += building.informations.surface ?? 0;
    numberOfSensors += building.informations.number_of_sensors ?? 0;
    if (building.informations.mainActivity) {
      activities.add(building.informations.mainActivity);
    }
    if (building.informations.comfort) {
      sensorsType.add(t("Comfort", {ns: "common"}));
    }
    if (building.informations.energy) {
      sensorsType.add(t("Energy", {ns: "common"}));
    }
  }

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);
  
  const formatedTotalSurface = new Intl.NumberFormat(i18n.language).format(totalSurface);
  const activitiesTypeString = Array.from(activities).join(", ");
  const sensorsTypeString = Array.from(sensorsType).join(", ");

  return {
    t,
    detail: {
      totalSurface: formatedTotalSurface ?? "-",
      numberOfBuildings: selectedBuildings.length ?? "-",
      activitiesType: activitiesTypeString.length > 0 ? activitiesTypeString : "-",
      sensorsType: sensorsTypeString.length > 0 ? sensorsTypeString : "-",
      numberOfSensors: numberOfSensors ?? "-",
    },
    link: park_link,
  };
}