import { useRef } from "react";
import { CustomToolTip } from "../tooltip/ToolTips";
import Metric from "./metric";
import { SensorData } from "./sensors-list";

export function SensorListData({sensor}: Props) {
  const hoverCard = useRef(null);
      
  const handleMouseEnter = () => {
    hoverCard.current.style.display = "block";
  };
  
  const handleMouseLeave = () => {
    hoverCard.current.style.display = "none";
  };

    
  return (
    <>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className={"d-flex flex-column"} >
          <Metric 
            unit="degree" 
            value={sensor.lastTemperature} 
            threshold={sensor.buildingThreshold?.temperature} 
            occupancyTime={sensor.buildingOccupancy} 
            last_message_ts={sensor.ts}
          />

          <Metric 
            unit="partsPerMillion" 
            value={sensor.lastCo2} 
            threshold={sensor.buildingThreshold?.air_quality} 
            last_message_ts={sensor.ts}
          />
        </div>
        <CustomToolTip buildingName={sensor.buildingName} threshold={sensor.buildingThreshold} hoverCard={hoverCard} />
      </div>
    </>
  );
}

type Props = {
    sensor: SensorData;
}