import SensorSelect, { SelectOption } from "@src/common/select";
import { tableFilter as storeFilter } from "@src/store/reducers/dashboard";
import { RootState } from "@src/store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MultiValue } from "react-select";
import {useTranslation} from "react-i18next";


function getOptionsFromStringArray(array: string[]) {
  return array.map((value) => {
    return  {
      label: value,
      value,
      color: "#000000",
      type: ""
    };
  });
}

export default function TableFilter() {
  const {types, filter} = useSelector((state: RootState) => state.dashboard.table);
  const allEquipments = useSelector((state: RootState) => state.dashboard.table.equipments);

  const [typeOptions, setTypeOption] = useState<MultiValue<SelectOption>>([]);
  const [typeOptionsSelected, setTypeOptionsSelected] = useState<MultiValue<SelectOption>>([]);
  const searchParams = new URLSearchParams(window.location.search);

  const dispatch = useDispatch();
  const dispatchFilter = (data: any) => dispatch(storeFilter(
    Object.assign({}, filter, data))
  );

  const {t, i18n} = useTranslation("dashboard");

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  
    const filters = JSON.parse(searchParams.get("filters") ?? "{}");
    const roomTypes = filters.roomType?.split(",");
    if (!roomTypes) {
      return;
    }
    const roomTypeOptions = getOptionsFromStringArray(roomTypes);
    setTypeOptionsSelected(roomTypeOptions);
  }, []);

  useEffect(() => {
    const filters = JSON.parse(searchParams.get("filters") ?? "{}");
    const urlRoomTypes = filters.roomType?.split(",") ?? [];

    if (!filter.types.length && !urlRoomTypes.length) {
      dispatchFilter({equipments: allEquipments});
      return;
    }
    else if (!filter.types.length && urlRoomTypes.length) {
      const filteredEquipments = allEquipments.filter(
        e => urlRoomTypes.includes(e.gui_informations.roomType)
      );
      dispatchFilter({equipments: filteredEquipments});
      return;
    }
    const roomTypes = filter.equipments.map(e => e.gui_informations.roomType);
    const filteredEquipments = allEquipments.filter(
      e => roomTypes.includes(e.gui_informations.roomType)
    );
    dispatchFilter({equipments: filteredEquipments});
  }, [allEquipments]);
  
  useEffect(() => {
    const options = getOptionsFromStringArray(types);
    setTypeOption(options);
  }, [types]);
  
  return (
    <div className="mb-5">
      <label htmlFor="data-type">{t("Room Type")}</label>
      <SensorSelect
        placeholder={t("All types")}
        isMulti={true}
        options={typeOptions}
        value={typeOptionsSelected}
        onChange={(options) => {
          setTypeOptionsSelected(options);
          const equipmentByTypes = !(options.length) ? allEquipments : allEquipments.filter(
            e => options.map(o => o.value).includes(e.gui_informations.roomType)
          );
          dispatchFilter({types: options, equipments: equipmentByTypes});

          const filters = JSON.parse(searchParams.get("filters") ?? "{}");
          const newValue = options.map(o => o.value).join(",");
          let newFilterType = filters;
          if (!newValue) {
            delete filters.roomType;
          } else {
            newFilterType = {...filters, roomType: newValue};
          }
          searchParams.set("filters", JSON.stringify(newFilterType));
          updateURL(searchParams);
        }}
        className=""
      />
    </div>
  );
}


function updateURL(params: URLSearchParams) {
  const newUrl = `${window.location.pathname}?${params.toString()}`;
  if (window.history.pushState) {
    window.history.pushState({path: newUrl}, "", newUrl);
  } else {
    window.location.href = newUrl;
  }
};