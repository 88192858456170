import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import { useBuildingFitlerPresenter } from "./use-building-filter-presenter";

export default function BuildingFilter() {
  const presenter = useBuildingFitlerPresenter();
  const { t } = presenter;

  return (
    <Box sx={{ 
      display: "flex", flexDirection: "column", gap: 3, 
      p: 2, paddingTop: 0, width: "100%" 
    }}>

      <FormControl sx={{ minWidth: 200 }}>
        <Autocomplete
          onChange={(_, values) => presenter.onChangeHandler(values, "cities")}
          sx={{ m: 1 }}
          size="small"
          multiple
          options={presenter.options.cities}
          getOptionLabel={(option) => option.label}
          disableCloseOnSelect
          // By default MUI want to compare the options with their references, not by value
          isOptionEqualToValue={presenter.customCompareValue}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t("City")}
            />
          )}
        />
      </FormControl>

      <FormControl sx={{ minWidth: 200 }}>
        <Autocomplete
          onChange={(_, values) => presenter.onChangeHandler(values, "networks")}
          sx={{ m: 1 }}
          size="small"
          multiple
          options={presenter.options.networks}
          getOptionLabel={(option) => t(option.label, {ns: "common"})}
          disableCloseOnSelect
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t("Network")}
            />
          )}
        />
      </FormControl>

      <FormControl sx={{ minWidth: 200 }}>
        <Autocomplete
          onChange={(_, values) => presenter.onChangeHandler(values, "constructionYears")}
          sx={{ m: 1 }}
          size="small"
          multiple
          options={presenter.options.constructionYears}
          getOptionLabel={(option) => t(option.label)}
          disableCloseOnSelect
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t("Construction year")}
            />
          )}
        />
      </FormControl>

      <FormControl sx={{ minWidth: 200 }}>
        <Autocomplete
          onChange={(_, values) => presenter.onChangeHandler(values, "mainActivities")}
          sx={{ m: 1 }}
          size="small"
          multiple
          options={presenter.options.mainActivities}
          getOptionLabel={(option) => option.label}
          disableCloseOnSelect
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t("Main activity")}
            />
          )}
        />
      </FormControl>
    </Box>
  );
}