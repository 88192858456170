import {useTranslation} from "react-i18next";
import {useEffect, useMemo, useState} from "react";
import { useSearchParams } from "react-router-dom";
import { useDependencies } from "@src/app/react/DependenciesProvider";
import { fetchConsumption } from "@src/dashboards/parks/core/usecases/fetch-consumption.usecase";
import { RootState, useAppDispatch } from "@src/store";
import { fetchPark } from "@src/dashboards/parks/core/usecases/fetch-park.usecase";
import { useSelector } from "react-redux";

export function useDashboardParkPage() {
  const {t, i18n} = useTranslation("common");
  const {dateProvider} = useDependencies();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const defaultYear = searchParams.get("year") ?? "default";
  const [selectedYear, setSelectedYear] = useState(defaultYear);
  const consumptionStatus = useSelector((state: RootState) => state.parkDashboard.consumption.status);

  const dateRange = useMemo(() => {
    return dateProvider.generateDatesFromYear(selectedYear);
  }, [selectedYear]);

  useEffect(() => {
    const parkId = searchParams.get("parc");
    dispatch(fetchConsumption({parkId, dateRange}));
    dispatch(fetchPark(searchParams));
  },  []);

  useEffect(() => {
    const parkId = searchParams.get("parc");
    dispatch(fetchConsumption({parkId, dateRange}));
  }, [dateRange]);

  return {
    t,
    i18n,
    selectedYear,
    setSelectedYear,
    consumptionStatus
  };
} 