import { RootState } from "@src/store";
import { useEffect } from "react";
import {useTranslation} from "react-i18next";
import { useSelector } from "react-redux";

export function useTotalIndicators() {
  const {t, i18n} = useTranslation("common");
  const selectedBuildings = useSelector((state: RootState) => state.parkDashboard.selectedBuildings) ?? [];
  const data = useSelector((state: RootState) => state.parkDashboard.consumption.data) ?? [];
  const selectedData = data.filter((item) => selectedBuildings.map(b => b.id).includes(item.building.id));

  let totalConsumption = 0;
  let totalExpenses = 0;
  let totalEmissions = 0;
  const totalSaving = 0;

  for (const item of selectedData) {
    totalConsumption += item.consumed;
    totalExpenses += item.expenses_vat_excl;
    totalEmissions += item.emissions;
  }

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);

  return {
    t,
    i18n,
    totalConsumption,
    totalExpenses,
    totalEmissions,
    totalSaving,
  };
}