export default {
  "Filters": "Filtres",
  "Periods": "Périodes",
  "Measurement points": "Points de mesure",
  "Outdoor temperature": "Température extérieure",
  "Room Type": "Type de salle",
  "Select": "Sélectionner",
  "Floors": "Étages",
  "Zones": "Zones",
  "Tenants": "Loacataires",
  "Select all": "Sélectionner tout",
  "Unselect all": "Déselectionner tout",
  "Sensors and CVC systems": "Capteurs et systèmes CVC",
  "Delivery Point": "Point de livraison",
  "Sub meter": "Sous-Compteur",
  "Sensors": "Capteurs",
  "No data available for this graph": "Aucune donnée disponible pour ce graphique",
  "Loading header in pogress ...": "Chargement de l'en-tête en cours ...",
  "Loading chart in progress ...": "Chargement du graphique en cours ...",
  "Day": "Jour",
  "Month": "Mois",
  "Air quality": "Qualité d'air",
  "Winter": "Hiver",
  "Summer": "Eté",
  "Yearly": "Annuel",
  "Period": "Période",
  "Min": "Min",
  "Max": "Max",
  "Activate": "Activer",
  "Thresholds": "Seuils",
  "Temperature": "Température",
  "Humidity": "Humidité",
  "CO2 Rate": "Taux de CO2",
  "Luminosity": "Luminosité",
  "Save": "Enregistrer",
  "Not Categorized": "Sans catégorie",
  "Room type": "Type de salle",
  "No options available": "Aucune option disponible",
};
