import { RootState } from "@src/store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export function useBuildingDistributionPresenter() {
  const {t, i18n} = useTranslation("dashboardPark");

  const selectedBuildings = useSelector((state: RootState) => state.parkDashboard.selectedBuildings);

  const networks = {
    "electricity_network": 0,
    "gas_network": 0,
    "urban_network": 0,
    "cooling_network": 0,
    "water_network": 0
  };

  const dpes = {
    "A": 0,
    "B": 0,
    "C": 0,
    "D": 0
  };

  const ges = {
    "A": 0,
    "B": 0,
    "C": 0,
    "D": 0
  };


  for (const building of selectedBuildings) {
    networks.electricity_network += building.configuration.electricity_network ? 1 : 0;
    networks.gas_network += building.configuration.gas_network ? 1 : 0;
    networks.urban_network += building.configuration.urban_network ? 1 : 0;
    networks.cooling_network += building.configuration.cooling_network ? 1 : 0;
    networks.water_network += building.configuration.water_network ? 1 : 0;

    dpes.A += building.informations.dpeLabel === "A" ? 1 : 0;
    dpes.B += building.informations.dpeLabel === "B" ? 1 : 0;
    dpes.C += building.informations.dpeLabel === "C" ? 1 : 0;
    dpes.D += building.informations.dpeLabel === "D" ? 1 : 0;

    ges.A += building.informations.gesLabel === "A" ? 1 : 0;
    ges.B += building.informations.gesLabel === "B" ? 1 : 0;
    ges.C += building.informations.gesLabel === "C" ? 1 : 0;
    ges.D += building.informations.gesLabel === "D" ? 1 : 0;
  }

  const networksDoughnut = [
    {name: t("Electricity"), value: networks.electricity_network, fill: "#ffe45e", unit: ""},
    {name: t("Gas"), value: networks.gas_network, fill: "#cdc1ff", unit: ""},
    {name: t("Heating network"), value: networks.urban_network, fill: "#e3635d", unit: ""},
    {name: t("Cooling network"), value: networks.cooling_network, fill: "#5aa9e6", unit: ""},
    {name: t("Water"), value: networks.water_network, fill: "#90f3ff", unit: ""}
  ];
  
  const dpeDoughnut = [
    {name: "A", value: dpes.A, fill: "#60d394", unit: ""},
    {name: "B", value: dpes.B, fill: "#ffd97d", unit: ""},
    {name: "C", value: dpes.C, fill: "#ff9b85", unit: ""},
    {name: "D", value: dpes.D, fill: "#ee6055", unit: ""},
  ];

  const gesDoughnut = [
    {name: "A", value: ges.A, fill: "#60d394", unit: ""},
    {name: "B", value: ges.B, fill: "#ffd97d", unit: ""},
    {name: "C", value: ges.C, fill: "#ff9b85", unit: ""},
    {name: "D", value: ges.D, fill: "#ee6055", unit: ""},
  ];
  
  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);

  return {
    t,
    networksDoughnut,
    dpeDoughnut,
    gesDoughnut,
  };
}
