import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Select, { MultiValue } from "react-select";
import { Building, Equipment } from "../../../../common/api";
import { SelectOption } from "../../../../common/select";

import Loading from "@src/common/components/is-loading";

import {
  ButtonExport,
  ModalCharts, SensorState,
  SortFilter
} from "../index";
import axios from "axios";
import Papa from "papaparse";
import {useTranslation} from "react-i18next";
import { Thresholds } from "@src/common";
import { OccupancyTimeCharacteristics } from "@src/common/api/building";
import { SensorListData } from "./sensor-list-data";

interface Props {
    buildings: Building[];
    equipments: Equipment[];
}

const useFetchDashboardSeries = (params: URLSearchParams, equipments: any[]) => {
  const [data, setData] = useState<DashboardSeriesResponse | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);

    axios.get<DashboardSeriesResponse>(
      `/api/v1/comfort/dashboard/series?${params}&deveui="${equipments?.map(sensor => sensor.name).join(",")}"`,
      { signal: controller.signal }
    )
      .then((response) => {
        setData(response.data);
        setError(null);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.error("Request canceled:", err.message);
        } else {
          setError(err);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, [params, equipments]);

  return { data, error, isLoading };
};

const generateLimitSelectOption = (limit: string | null) => {
  const defaultValues = ["10", "20", "50"];
  if (limit !== null) {
    defaultValues.unshift(limit);
  }
  return [... new Set(defaultValues)].map(
    v => {
      return {label: v, value: v, type: "", color: ""};
    }
  );
};

export default function SensorsList({buildings, equipments}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [timezone, setTimezone] = useState("Europe/Paris");
  const [params] = useSearchParams();
  const { data, error } = useFetchDashboardSeries(params, equipments);

  const {t, i18n} = useTranslation("dashboard");

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);

  const [maxPage, setMaxPage] = useState<number>(1);
  const [page, setPage] = useState<number>(+params.get("page") || 1);
  const [dataType, setDataType] = useState<string>(params.get("data_type"));
  const [sortType, setSortType] = useState<string>(params.get("sort_type"));
  const [sortField, setSortField] = useState<string>(params.get("sort_field"));

  const [sensors, setSensors] = useState<any>();
  const limitSelectOption: MultiValue<SelectOption> = generateLimitSelectOption(params.get("limit"));
  const [limitSelected, setLimitSelected] = useState<SelectOption>(
    limitSelectOption[0]
  );
  const [open, setOpen] = useState<any>();
  const handleOpen = (id: string) => setOpen((oldState: any) => {
    return {
      ...oldState,
      [id]: true
    };
  });
  const handleClose = (id: string) => setOpen((oldState: any) => {
    return {
      ...oldState,
      [id]: false
    };
  });

  const getMaxPage = (total: number) => {
    const limit = +limitSelected.value || 1;
    return Math.ceil(total / limit) || 1;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("view", "list");
    updateURL(searchParams);
    axios(`/api/v1/timezone?${params}`).then(response => setTimezone(response.data));
  }, []);

  useEffect(() => {
    const limit = params.get("limit");
    let option = limitSelectOption[0];
    if (limit) {
      option = {label: limit, value: limit, type: "", color: ""};
    }
    setLimitSelected(option);

    const _dataType = params.get("data_type");
    const _sortField = params.get("sort_field");
    const _sortType = params.get("sort_type");
    if (_dataType && _sortField && _sortType) {
      setDataType(_dataType);
      setSortField(_sortField);
      setSortType(_sortType);
    }

    if (data) {
      const max = data?.total ?? 1;
      const newMaxPage = getMaxPage(max);
      setMaxPage(newMaxPage);
      const _sensors = data?.sensors.map((item: any) => {
        const buildingFound = buildings.find(b => b.id === item.buildingId);
        return {
          ...item,
          buildingName: buildingFound?.name,
          buildingRegion: buildingFound?.informations?.region,
          buildingThreshold: buildingFound?.informations?.metrology_thresholds,
          buildingOccupancy: buildingFound?.thermal_characteristics?.general?.occupancyTime,
        };
      });
      const modals = equipments.map(e => {
        return {[e.name]: false};
      });
      setOpen(modals);
      setSensors(_sensors);
      if (params.get("page")) {
        setPage(+params.get("page"));
      }
    }
  }, [data, equipments]);

  useEffect(() => {
    if (page > maxPage) {
      setPage(maxPage);
    }
  }, [sensors]);

  const fetchSensors = async (
    page: number, limit: number,
    sort_type?: string, sort_field?: string, data_type?: string
  ) => {
    setIsLoading(true);
    try {
      const BASE_URL = "/api/v1/comfort/dashboard/series";
      const deveui = equipments?.map(sensor => sensor.name).join(",");
      let sortingParams = "";
      if (sort_type && sort_field && data_type) {
        sortingParams = `&sort_type=${sort_type}&sort_field=${sort_field}&data_type=${data_type}`;
      }
      const result = await fetch(
        `${BASE_URL}?${params}&page=${page}&limit=${limit}&deveui="${deveui}"${sortingParams}`
      );
      const data: DashboardSeriesResponse = await result.json();
      const _sensors = data.sensors.map((item: any) => {
        const buildingFound = buildings.find(b => b.id === item.buildingId);
        return {
          ...item,
          buildingName: buildingFound?.name,
          buildingRegion: buildingFound?.informations?.region,
        };
      });
      setSensors(_sensors);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const tenMinute = 5 * 60 * 1_000;
    const intervalID = setInterval(
      async () => {
        await fetchSensors(page, +limitSelected.value, sortType, sortField, dataType);
      },
      tenMinute
    );
    return () => {
      clearInterval(intervalID);
    };
  }, [equipments]);
    
  const onPageHandler = async (pageNumber: number) => {
    let newPage = page + pageNumber;
    if (newPage <= 1) {
      newPage = 1;
    } else if (newPage >= maxPage) {
      newPage = maxPage;
    }
    setPage(newPage);
    updatePaginationURLParams(limitSelected.value, newPage);
    await fetchSensors(
      newPage, +limitSelected.value, sortType, sortField, dataType
    );
  };

  const updateSortURLParams = (sortType?: string, sortField?: string, dataType?: string) => {
    const params = new URLSearchParams(window.location.search);
    if (sortType && sortField && dataType) {
      params.set("data_type", dataType);
      params.set("sort_type", sortType);
      params.set("sort_field", sortField);
    }
    updateURL(params);
  };

  const removeSortURLParams = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete("data_type");
    params.delete("sort_type");
    params.delete("sort_field");
    updateURL(params);
  };

  const updatePaginationURLParams = (limit: string | number, page?: string | number) => {
    /**
         * Update URL without re-render components.
         */
    const params = new URLSearchParams(window.location.search);
    params.set("limit", limit.toString());
    if (page) {
      params.set("page", page.toString());
    }
    updateURL(params);
  };

  const updateURL = (params: URLSearchParams) => {
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    if (window.history.pushState) {
      window.history.pushState({path: newUrl}, "", newUrl);
    } else {
      window.location.href = newUrl;
    }
  };

  const onExportHandler = () => {
    setIsExporting(true);
    axios(`/api/v1/comfort/dashboard/export/series?
      precision=1
      &deveui="${equipments?.map(sensor => sensor.name).join(",")}"
      &${params}
      &page=${page}
      &limit=${limitSelected.value}
    `)
      .then(response => {
        const dataParsed = Papa.unparse(response?.data ?? [], {delimiter: ";"});
        const csvData = new Blob(["\uFEFF" + dataParsed], {type: "text/csv;charset=utf-8;"});
        const url = URL.createObjectURL(csvData);
        const link = document.createElement("a");
        link.href = url;
        link.download = "dashboard_comfort_series.csv";
        link.click();
        URL.revokeObjectURL(url);
      })
      .finally(() => setIsExporting(false));
  };

  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    minHeight: 600,
    maxHeight: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 3,
    overflow: "scroll",
    p: 4,
  };

  const fetchSortedSensors = async (event: any, sortField: string) => {
    const [dataType, sortType] = event.currentTarget.dataset.value.split(",");
    setDataType(dataType);
    setSortField(sortField);
    setSortType(sortType);

    if (dataType && sortType) {
      updateSortURLParams(sortType, sortField, dataType);
    } else {
      removeSortURLParams();
    }
    await fetchSensors(
      page, +limitSelected.value, sortType, sortField, dataType
    );
  };

  if (!data) {
    return <div className={"text-center"}><Loading/></div>;
  } else if (error) {
    return <h1>{t("Error on loading dashboard")}</h1>;
  }
  
  return (
    <>
      <div className="mb-3 d-flex">
        <div className="col col-2">
          <label htmlFor="limit" className="font-weight-bold">
            {t("Sensors by page")}
          </label>
          <Select
            id="limit"
            options={limitSelectOption}
            value={limitSelected}
            onChange={async (option) => {
              setLimitSelected(option);
              const max = data?.total ?? 0;
              const limit = +option.value || 0;
              setMaxPage(Math.ceil(max / limit));
              updatePaginationURLParams(limit);
              await fetchSensors(page, limit);
            }}
            className="pl-0"
          />
        </div>
        <div className="col col-2 text-center">
          <p className="font-weight-bold">{t("Number of sensors")}</p>
          <p>{data?.total}</p>
        </div>
        <div className={"col col-8 d-flex align-items-center justify-content-end"}>
          {isExporting ? <Loading/> : <></>}
          <ButtonExport onExportHandler={onExportHandler}/>
        </div>
      </div>
      {
        isLoading ?
          <div className={"text-center"}>
            <Loading/>
          </div>
          :
          <table className={"table text-center"}>
            <thead>
              <tr id="empty-row">
                <th></th>
                <th></th>
                <th></th>
                <th colSpan={3} className={"text-center table-col-group"}>
                  {t("Temperature / Air Quality")}
                </th>
                <th></th>
              </tr>
              <tr>
                <th rowSpan={2}>{t("Region")}</th>
                <th rowSpan={2}>{t("Building")}</th>
                <th rowSpan={2}>{t("Sensor")}</th>
                <th className={"start-col-group"}>
                  {t("Last reading")}
                  <SortFilter type="last" onSelectHandler={fetchSortedSensors}/>
                </th>
                <th>
                  {t("Average occupancy")}
                  <SortFilter type="occupied" onSelectHandler={fetchSortedSensors}/>
                </th>
                <th className={"end-col-group"}>
                  {t("Average vacancy")}
                  <SortFilter type="unoccupied" onSelectHandler={fetchSortedSensors}/>
                </th>
                <th>{t("Curve")}</th>
                <th rowSpan={2}>{t("Sensor state")}</th>
              </tr>
            </thead>
            <tbody>
              {
                data?.sensors.length ?
                  sensors?.map((sensor: SensorData, index: number) => (
                    <tr key={sensor.buildingId + index + sensor.label}>
                      <td>{sensor.buildingRegion}</td>
                      <td>{sensor.buildingName}</td>
                      <td>{sensor.label}</td>
                      <td>
                        <SensorListData sensor={sensor} />
                      </td>
                      <td>
                        <SensorListData sensor={sensor} />
                      </td>
                      <td>
                        <SensorListData sensor={sensor} />
                      </td>
                      <td>
                        <a href="#" onClick={(event) => {
                          event.preventDefault();
                          handleOpen(sensor.deveui);
                        }}>{t("See curve")}</a>
                        <Modal
                          open={open[sensor.deveui] || false}
                          onClose={() => handleClose(sensor.deveui)}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                            <ModalCharts
                              buildingId={sensor.buildingId}
                              equipment={equipments?.find(e => e.name === sensor.deveui)}
                              startDate={moment().subtract(24, "hours").format("YYYY-MM-DD HH:mm:ss")}
                              endDate={moment().format("YYYY-MM-DD HH:mm:ss")}/>

                          </Box>

                        </Modal>
                      </td>
                      <td className={"d-flex flex-column justify-content-center align-items-center"}>
                        <SensorState date={sensor.ts} timezone={timezone} />
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td colSpan={8}>
                      <h2>
                        {t("No data available")}
                      </h2>
                    </td>
                  </tr>
              }
            </tbody>
          </table>
      }
      <div>
        <ul className="pagination m-2">
          <li className={`page-item m-1 ${page <= 1 ? "disabled" : ""}`}>
            <button className="page-link page-prev"
              onClick={() => onPageHandler(-1)}
            >{t("Previous")}
            </button>
          </li>
          <li className={"d-flex align-items-center ml-2 mr-2"}>
            {page} / {maxPage}
          </li>
          <li className={`page-item m-1 ${page >= maxPage ? "disabled" : ""}`}>
            <button
              className="page-link page-next"
              id="sensors-page-next"
              onClick={() => onPageHandler(+1)}
            >{t("Next")}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}

interface DashboardSeriesResponse {
    total: number;
    sensors: SensorData[];
}

export interface SensorData {
    buildingId: number;
    buildingRegion?: string;
    buildingName?: string;
    buildingThreshold?: Thresholds;
    buildingOccupancy?: OccupancyTimeCharacteristics;
    label: string;
    lastCo2: number;
    lastTemperature: number;
    occupiedCo2: number;
    occupiedTemperature: number;
    unoccupiedCo2: number;
    unoccupiedTemperature: number;
    ts: number;
    deveui: string;
}