export default {
  "Electricity": "Électricité",
  "Gas": "Gaz",
  "Heating network": "Réseau de chaud",
  "Cooling network": "Réseau de froid",
  "Water": "Eau",
  "12 rolling months": "12 mois glissants",
  "Energy": "Energie",
  "Comfort": "Confort",
  "No data available": "Aucune donnée disponible",
  "Loading ...": "Chargement en cours ...",
  "Download as XLS": "Télécharger en XLS",
  "Download as CSV": "Télécharger en CSV",
  "Download as PDF": "Télécharger en PDF",
  "Download as JPEG": "Télécharger en JPEG",
  "Download as PNG": "Télécharger en PNG",
  "Download as SVG": "Télécharger en SVG",
  "Download all graph as XLS": "Télécharger tous les graphiques en XLS",
  "View data table": "Voir le tableau de données",
  "Soon": "Bientôt",
};
