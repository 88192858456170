import { LinearProgress } from "@mui/material";
import { Stack } from "@mui/material";
import { useEmissionConsumptionPresenter } from "@src/dashboards/parks/react/sections/classifications/emissions/use-emission-consumption-presenter";

export function EmissionConsumption() {
  const presenter = useEmissionConsumptionPresenter();
  const {t} = presenter;

  return (
    <>
      <div className="card">
        <div className="card-header bg-transparent pd-b-0 pd-t-20 bd-b-0 text-center">
          <div className="d-flex justify-content-center">
            <h4 className="card-title mg-b-10">
              {t("CO2 emission ranking")}
              <br />
              <small className="text-muted">
                {t("kg CO2/m²/year")}
              </small>
            </h4>
          </div>
        </div>
        <div className="card-body">
          <ul className="sales-session mb-0">
            {
              presenter.consumptionData.map((data) => (
                <li key={data.buildingId}>
                  <div>
                    <div className="d-flex justify-content-between">
                      <h6>{data.buildingName}</h6>
                      <p className="fw-semibold mb-2">
                        {data.value}
                      </p>
                    </div>
                    <Stack spacing={2} sx={{ flexGrow: 1 }}>
                      <LinearProgress 
                        variant="determinate" 
                        sx={data.progressBarStyle}
                        value={data.progressBarValue} />
                    </Stack>
                  </div>
                </li>
              ))}
          </ul>
          <button className="page-link m-auto" onClick={presenter.handleShowMore}>
            {t(presenter.buttonText)}
          </button>
        </div>
      </div>
    </>
  );
}
