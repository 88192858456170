import {useMapOfBuildingsPresenter} from "@src/dashboards/parks/react/sections/maps/use-map-of-buildings-presenter";
import { LatLngExpression } from "leaflet";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

export function MapOfBuildings() {
  const presenter = useMapOfBuildingsPresenter();
  const {t} = presenter;

  return (
    <div className="card" style={{zIndex: 0}}>
      <div className="card-body">
        <div className="main-content-label park-map-card">
          {t("Buildings map")}
        </div>
        <div className="ht-300">
          <MapContainer style={{ height: "300px" }} center={[48.85341, 2.3488]} zoom={10}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
              presenter.buildings.map(building => {
                return (
                  <Marker 
                    key={building.id} 
                    position={building.position as LatLngExpression}
                    icon={presenter.DefaultIcon}>
                    <Popup>
                      <a href={building.url}>{building.name}</a>
                      <br/>
                      <span>{building.address}</span>
                    </Popup>
                  </Marker>
                );
              })
            }
          </MapContainer>
        </div>
      </div>
    </div>
  );
}
