import { useTranslation } from "react-i18next";

export function useTotalIndicatorPresenter(value: number) {
  const {t, i18n} = useTranslation("dashboardPark");
  const formatter = new Intl.NumberFormat(i18n.language);
  const formattedValue = isNaN(value) ? "N/A" : formatter.format(Math.round(value));

  return {
    t,
    i18n,
    formattedValue
  };
}
