
import {ParkDomainModel} from "@src/dashboards/parks/core/models/park.domain-model";
import {fetchConsumptionFailed} from "@src/dashboards/parks/core/store/park";
import {createAppAsyncThunk} from "@src/store/withTypes";
import HttpError, {extractStatusCode} from "@src/shared/http-error";
import { DateRange } from "@src/core/ports/date.provider";

type Params = {
  parkId: string;
  dateRange: DateRange;
}

export const fetchConsumption = createAppAsyncThunk(
  "dashboardPark/fetchConsumption",
  async (params: Params, {dispatch, extra: { httpGateway }}
  ): Promise<ParkDomainModel.Consumption[]> => {
    const {parkId, dateRange} = params;
    const url = `/api/v1/energy/consumption/park/${parkId}?date_from=${dateRange.dateFrom}&date_to=${dateRange.dateTo}`;
    try {
      const response = await httpGateway.get<ParkDomainModel.Consumption[]>(url);
      return response.data;
    } catch (e) {
      const statusCode = extractStatusCode(e);
      dispatch(fetchConsumptionFailed(statusCode));
      throw new HttpError(statusCode, e.message, {});
    }
  });