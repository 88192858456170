
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useConsumption } from "../use-consumption";

export function useEnergyConsumptionPresenter() {
  const {t, i18n} = useTranslation("dashboardPark");
  const { handleShowMore, consumptionData, buttonText } = useConsumption("consumed");
  
  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);

  return {
    t,
    i18n,
    handleShowMore,
    consumptionData,
    buttonText,
  };
}